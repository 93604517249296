import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import HighlightsList from '@components/ui/highlights-list/HighlightsList';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const IpoPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		t`Usługi z zakresu corporate governance oraz realizacji obowiązków informacyjnych spółek notowanych na Giełdzie Papierów Wartościowych w Warszawie.`,
		t`Doradztwo we wdrażaniu Dobrych Praktyk Notowanych na Giełdzie Papierów Wartościowych a także w zakresie dostosowywania regulaminów zarządów, rad nadzorczych i walnych zgromadzeń do zasad corporate governance.`
	];

	const highlights = [
		{
			label: t`5 lat`,
			description: t`realizacji obowiązków informacyjnych spółki Multimedia Polska S.A. na Giełdę Papierów Wartościowych w Warszawie`
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>
						<Trans>IPO i Corporate Governance</Trans>
					</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-md lg:max-w-2xl md:pr-10 lg:pr-20">
								<div className="text-sm uppercase">
									<Trans>Specjalizacje</Trans>
								</div>
								<h1 className="font-serif text-4xl font-bold">
									<Trans>IPO i Corporate Governance</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../../assets/images/fuzje-i-przejecia-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid gap-8 py-8 lg:gap-16 md:grid-cols-2 md:py-12">
									<div className="text-gray-dark">
										<h2 className="mb-3 font-bold">Zakres usług</h2>
										<HighlightsList items={data} />
									</div>
									<div>
										<div>
											<div className="grid gap-2 lg:gap-3">
												{highlights.map((item) => (
													<div className="p-4 lg:p-6 border border-gray-medium">
														<div className="w-full">
															<div className="mb-3 text-2xl font-bold">
																{item.label}
															</div>
															<div className="text-gray-dark text-sm">
																{item.description}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default IpoPage;
